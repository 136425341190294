<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("messages.title.warn") }}</h1>
      <h2>{{ $t("messages.error.page_404") }}</h2>
    </vca-card>
  </vca-card>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
